/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './bio-report-chart.scss';
import 'shared/styles/animation.scss';
import Lottie from 'react-lottie-player';
import movingCircle from './moving-circle.json';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { format } from 'date-fns';
import { IBioReport } from 'shared/interface/bio-report.interface';
import { IUser } from 'shared/interface/user.interface';
import UserService from 'shared/service/user.service';
import { buildFileView } from 'shared/service/file';
import { IQuestionSet } from 'shared/interface/question-set.interface';
import QuestionSetTitle from 'shared/components/question-set/QuestionSetTitle';

interface IProp {
    finalResult?: {
        finalPoint: number;
        finalTotalPoint: number;
        percentage: number;
        totalViolated: number;
    };
    bioReport: IBioReport;
}

export default function BioReportChart(prop: IProp) {
    const { finalResult, bioReport } = prop;
    const { t, i18n } = useTranslation();
    const [x, setX] = useState<number>(-1);
    const [y, setY] = useState<number>(-1);
    const [performer, setPerformer] = useState<IUser>();
    const preProcess = () => {
        if (finalResult) {
            if (finalResult?.percentage < 80) {
                setY(2);
            } else if (finalResult?.percentage >= 80 && finalResult?.percentage < 90) {
                setY(1);
            } else {
                setY(0);
            }

            if (finalResult.totalViolated > 3) {
                setX(0);
            } else if (finalResult.totalViolated >= 1 && finalResult.totalViolated <= 3) {
                setX(1);
            } else {
                setX(2);
            }
        }
    };

    const getRisk = () => {
        if ((x === 0 && y === 0) || (x === 1 && y === 1) || (x === 1 && y === 2)) {
            return {
                bg: '#fbb91120',
                text: '#fbb911',
                title: 'high',
                class: 'bg-warning'
            };
        } else if ((x === 0 && y === 2) || (x === 0 && y === 1)) {
            return {
                bg: '#e7323220',
                text: '#e73232',
                title: 'warning',
                class: 'bg-danger'
            };
        } else if (x === 2 && y === 0) {
            return {
                bg: '#20966320',
                text: '#209663',
                title: 'low',
                class: 'bg-success'
            };
        } else {
            return {
                bg: '#0a68f420',
                text: '#0a68f4',
                title: 'medium',
                class: 'bg-blue'
            };
        }
    };

    const getPerformer = async () => {
        if (bioReport) {
            const res = await UserService.getUserInfo(bioReport?.updatedBy);
            setPerformer(res);
        }
    };

    useEffect(() => {
        preProcess();
        getPerformer();
    }, [finalResult]);

    const renderTooltip = (props: any) => {
        const riskResult = getRisk();
        return (
            <Tooltip id="button-tooltip" {...props} className={["tooltips-glass", `${riskResult?.class}`].join(' ')}>
                <div style={{ width: 240 }} className="p-3">
                    <div className="d-flex justify-content-between fs-6">
                        <span>{`% ${t('passed')}`}</span>
                        <span>{bioReport?.percentage?.totalEvaluation.toFixed(2)}%</span>
                    </div>
                    <div className="d-flex justify-content-between ms-2">
                        <span>{`${t('review-design')}`}</span>
                        <span>{bioReport?.percentage?.designEvaluation.toFixed(2)}%</span>
                    </div>
                    <div className="d-flex justify-content-between ms-2">
                        <span>{`${t('assessment of process')}`}</span>
                        <span>{bioReport?.percentage?.processEvaluation.toFixed(2)}%</span>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between fs-6">
                        <span>{`${t('violated-point')}`}</span>
                        <span>{finalResult?.totalViolated}</span>
                    </div>
                </div>
            </Tooltip>
        );
    };

    return (
        <div className="row py-2 bg-white">
            <div className="col-12 col-md-6">
                <h3 className="ps-2">{t('information')}</h3>
                <ul className="list-group">
                    <li className="list-group-item border-0 border-bottom py-3">
                        <div className="d-flex justify-content-between">
                            <span className="text-secondary">{t('performer')}</span>
                            <span className="fw-semibold">
                                <img
                                    className="rounded-circle"
                                    alt=""
                                    src={buildFileView(performer?.avatar)}
                                    style={{ width: 24, height: 24 }}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = '/image/picture/default.jpeg';
                                    }}
                                />
                                {performer?.name}
                            </span>
                        </div>
                    </li>
                    <li className="list-group-item border-0 border-bottom py-3">
                        <div className="d-flex justify-content-between">
                            <span className="text-secondary">{t('perform-date')}</span>
                            <span className="fw-semibold">
                                {format(
                                    new Date(bioReport.updatedAt),
                                    i18n.language === 'vi' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                                )}
                            </span>
                        </div>
                    </li>
                    <li className="list-group-item border-0 border-bottom py-3">
                        <div className="d-flex justify-content-between">
                            <span className="text-secondary">{t('question-set')}</span>
                            <span className="fw-semibold">
                                <QuestionSetTitle id={bioReport.questionSet}></QuestionSetTitle>
                            </span>
                        </div>
                    </li>
                    <li className="list-group-item border-0 border-bottom py-3">
                        <div className="d-flex justify-content-between">
                            <div className="fs-5 p-2" style={{ backgroundColor: getRisk().bg }}>
                                {t('bio-report-result-title')}{' '}
                                <span style={{ color: getRisk().text }}>{t(getRisk().title)}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="col-12 col-md-6 py-2">
                <h3 className="ps-2">{t('chart')}</h3>
                <div className="bio-chart swing-in-right-fwd div-shadow p-0 p-sm-3">
                    <div className="row g-0">
                        <div className="col-1 bg-cargill-gray" />
                        <div className="col-11">
                            <div className="row g-0">
                                <div className="col-3 bg-cargill-gray" />
                                <div className="col-9 bg-cargill-gray-darker text-secondary text-uppercase text-center py-2">{`<- ${t(
                                    'fail-vital-point'
                                )} ->`}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-0">
                        <div className="col-1 bg-cargill-gray" />
                        <div className="col-11">
                            <div className="row g-0">
                                <div className="col-3 bg-cargill-gray" />
                                <div className="col-3 d-flex justify-content-center align-items-center border py-2">
                                    {'>3'}
                                </div>
                                <div className="col-3 d-flex justify-content-center align-items-center border py-2">
                                    1-3
                                </div>
                                <div className="col-3 d-flex justify-content-center align-items-center border py-2">
                                    0
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-0">
                        <div
                            className="col-1 d-flex flex-column justify-content-center text-secondary bg-cargill-gray-darker text-uppercase text-center"
                            style={{ writingMode: 'vertical-rl', transform: 'scale(-1)' }}>{`<- ${t(
                            'total-point'
                        )} ->`}</div>
                        <div className="col-11">
                            <div className="row g-0">
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center border text-center">
                                    90% - 100%
                                </div>
                                <div
                                    className={[
                                        'flex-column col-3 bio-chart-bg-yellow d-flex justify-content-center align-items-center border py-4',
                                        x === 0 && y === 0 && 'bio-chart-bg-yellow-active',
                                    ].join(' ')}>
                                    {x === 0 && y === 0 && (
                                        <OverlayTrigger
                                            placement="auto"
                                            delay={{ show: 100, hide: 200 }}
                                            overlay={renderTooltip}
                                            show={true}>
                                            <div>
                                                <Lottie
                                                    loop={true}
                                                    animationData={movingCircle}
                                                    play={true}
                                                    style={{ width: 30, height: 30 }}
                                                />
                                            </div>
                                        </OverlayTrigger>
                                    )}
                                    <span
                                        className={[
                                            'bio-chart-text-yellow text-center',
                                            x === 0 && y === 0 && 'text-active-chart',
                                        ].join(' ')}>
                                        {t('high')}
                                    </span>
                                </div>
                                <div
                                    className={[
                                        'col-3 bio-chart-bg-blue d-flex justify-content-center align-items-center border flex-column py-4',
                                        x === 1 && y === 0 && 'bio-chart-bg-blue-active',
                                    ].join(' ')}>
                                    {x === 1 && y === 0 && (
                                        <OverlayTrigger
                                            placement="auto"
                                            delay={{ show: 100, hide: 200 }}
                                            overlay={renderTooltip}
                                            show={true}>
                                            <div>
                                                <Lottie
                                                    loop={true}
                                                    animationData={movingCircle}
                                                    play={true}
                                                    style={{ width: 30, height: 30 }}
                                                />
                                            </div>
                                        </OverlayTrigger>
                                    )}
                                    <span
                                        className={[
                                            'bio-chart-text-blue text-center',
                                            x === 1 && y === 0 && 'text-active-chart',
                                        ].join(' ')}>
                                        {t('medium')}
                                    </span>
                                </div>
                                <div
                                    className={[
                                        'flex-column col-3 bio-chart-bg-green d-flex justify-content-center align-items-center border py-4',
                                        x === 2 && y === 0 && 'bio-chart-bg-green-active',
                                    ].join(' ')}>
                                    {x === 2 && y === 0 && (
                                        <OverlayTrigger
                                            placement="auto"
                                            delay={{ show: 100, hide: 200 }}
                                            overlay={renderTooltip}
                                            show={true}>
                                            <div>
                                                <Lottie
                                                    loop={true}
                                                    animationData={movingCircle}
                                                    play={true}
                                                    style={{ width: 30, height: 30 }}
                                                />
                                            </div>
                                        </OverlayTrigger>
                                    )}
                                    <span
                                        className={[
                                            'bio-chart-text-green text-center',
                                            x === 2 && y === 0 && 'text-active-chart',
                                        ].join(' ')}>
                                        {t('low')}
                                    </span>
                                </div>
                            </div>
                            <div className="row g-0">
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center border text-center">
                                    80% - 90%
                                </div>
                                <div
                                    className={[
                                        'flex-column col-3 bio-chart-bg-red d-flex justify-content-center align-items-center border py-4',
                                        x === 0 && y === 1 && 'bio-chart-bg-red-active',
                                    ].join(' ')}>
                                    {x === 0 && y === 1 && (
                                        <OverlayTrigger
                                            placement="auto"
                                            delay={{ show: 100, hide: 200 }}
                                            overlay={renderTooltip}
                                            show={true}>
                                            <div>
                                                <Lottie
                                                    loop={true}
                                                    animationData={movingCircle}
                                                    play={true}
                                                    style={{ width: 30, height: 30 }}
                                                />
                                            </div>
                                        </OverlayTrigger>
                                    )}
                                    <span
                                        className={[
                                            'bio-chart-text-red text-center',
                                            x === 0 && y === 1 && 'text-active-chart',
                                        ].join(' ')}>
                                        {t('warning')}
                                    </span>
                                </div>
                                <div
                                    className={[
                                        'flex-column col-3 bio-chart-bg-yellow d-flex justify-content-center align-items-center border py-4',
                                        x === 1 && y === 1 && 'bio-chart-bg-yellow-active',
                                    ].join(' ')}>
                                    {x === 1 && y === 1 && (
                                        <OverlayTrigger
                                            placement="auto"
                                            delay={{ show: 100, hide: 200 }}
                                            overlay={renderTooltip}
                                            show={true}>
                                            <div>
                                                <Lottie
                                                    loop={true}
                                                    animationData={movingCircle}
                                                    play={true}
                                                    style={{ width: 30, height: 30 }}
                                                />
                                            </div>
                                        </OverlayTrigger>
                                    )}
                                    <span
                                        className={[
                                            'bio-chart-text-yellow text-center',
                                            x === 1 && y === 1 && 'text-active-chart',
                                        ].join(' ')}>
                                        {t('high')}
                                    </span>
                                </div>
                                <div
                                    className={[
                                        'flex-column col-3 bio-chart-bg-blue d-flex justify-content-center align-items-center border py-4',
                                        x === 2 && y === 1 && 'bio-chart-bg-blue-active',
                                    ].join(' ')}>
                                    {x === 2 && y === 1 && (
                                        <OverlayTrigger
                                            placement="auto"
                                            delay={{ show: 100, hide: 200 }}
                                            overlay={renderTooltip}
                                            show={true}>
                                            <div>
                                                <Lottie
                                                    loop={true}
                                                    animationData={movingCircle}
                                                    play={true}
                                                    style={{ width: 30, height: 30 }}
                                                />
                                            </div>
                                        </OverlayTrigger>
                                    )}
                                    <span
                                        className={[
                                            'bio-chart-text-blue text-center',
                                            x === 2 && y === 1 && 'text-active-chart',
                                        ].join(' ')}>
                                        {t('medium')}
                                    </span>
                                </div>
                            </div>
                            <div className="row g-0">
                                <div className="col-3 d-flex flex-column justify-content-center align-items-center border text-center">
                                    {'<80%'}
                                </div>
                                <div
                                    className={[
                                        'flex-column col-3 bio-chart-bg-red d-flex justify-content-center align-items-center border py-4',
                                        x === 0 && y === 2 && 'bio-chart-bg-red-active',
                                    ].join(' ')}>
                                    {x === 0 && y === 2 && (
                                        <OverlayTrigger
                                            placement="auto"
                                            delay={{ show: 100, hide: 200 }}
                                            overlay={renderTooltip}
                                            show={true}>
                                            <div>
                                                <Lottie
                                                    loop={true}
                                                    animationData={movingCircle}
                                                    play={true}
                                                    style={{ width: 30, height: 30 }}
                                                />
                                            </div>
                                        </OverlayTrigger>
                                    )}
                                    <span
                                        className={[
                                            'bio-chart-text-red text-center',
                                            x === 0 && y === 2 && 'text-active-chart',
                                        ].join(' ')}>
                                        {t('warning')}
                                    </span>
                                </div>
                                <div
                                    className={[
                                        'flex-column col-3 bio-chart-bg-yellow d-flex justify-content-center align-items-center border py-4',
                                        x === 1 && y === 2 && 'bio-chart-bg-yellow-active',
                                    ].join(' ')}>
                                    {x === 1 && y === 2 && (
                                        <OverlayTrigger
                                            placement="auto"
                                            delay={{ show: 100, hide: 200 }}
                                            overlay={renderTooltip}
                                            show={true}>
                                            <div>
                                                <Lottie
                                                    loop={true}
                                                    animationData={movingCircle}
                                                    play={true}
                                                    style={{ width: 30, height: 30 }}
                                                />
                                            </div>
                                        </OverlayTrigger>
                                    )}
                                    <span
                                        className={[
                                            'bio-chart-text-yellow text-center',
                                            x === 1 && y === 2 && 'text-active-chart',
                                        ].join(' ')}>
                                        {t('high')}
                                    </span>
                                </div>
                                <div
                                    className={[
                                        'flex-column col-3 bio-chart-bg-blue d-flex justify-content-center align-items-center border py-4',
                                        x === 2 && y === 2 && 'bio-chart-bg-blue-active',
                                    ].join(' ')}>
                                    {x === 2 && y === 2 && (
                                        <OverlayTrigger
                                            placement="auto"
                                            delay={{ show: 100, hide: 200 }}
                                            overlay={renderTooltip}
                                            show={true}>
                                            <div>
                                                <Lottie
                                                    loop={true}
                                                    animationData={movingCircle}
                                                    play={true}
                                                    style={{ width: 30, height: 30 }}
                                                />
                                            </div>
                                        </OverlayTrigger>
                                    )}
                                    <span
                                        className={[
                                            'bio-chart-text-blue text-center',
                                            x === 2 && y === 2 && 'text-active-chart',
                                        ].join(' ')}>
                                        {t('medium')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
