import {
    IBioReport,
    IBioReportQuestion,
    IGroupViolated,
    IBioReportViolatedListQuestion,
    IBioReportComparationQuestion,
} from 'shared/interface/bio-report.interface';
import { QuestionType } from 'shared/enum/question.enum';
import {
    IBioReportQuestionRetail,
    IBioReportRetail,
    IBioReportViolatedListQuestionRetail,
    IGroupViolatedRetail,
    RetailPoint,
} from 'shared/interface/bio-report-retail.interface';

const getQuestionNumber = (
    bioReport: IBioReport,
    listIndex?: number,
    groupIndex?: number,
    questionIndex?: number
) => {
    let sum = 0;
    for (let i = 0; i < (listIndex || 0); i++) {
        if (!bioReport?.listQuestion[i].isGroupStyle) {
            sum += bioReport?.listQuestion[i].questions.length || 0;
        } else {
            let subSum = 0;
            bioReport.listQuestion[i].groupQuestions.forEach((e) => {
                subSum += e.questions.length;
            });
            sum += subSum;
        }
    }
    if (bioReport.listQuestion[listIndex || 0].isGroupStyle) {
        for (let i = 0; i < (groupIndex || 0); i++) {
            sum += bioReport.listQuestion[listIndex || 0].groupQuestions[i].questions.length;
        }
    }
    return sum + (questionIndex || 0) + 1;
};

const getQuestionNumberRetail = (
    bioReport: IBioReportRetail,
    listIndex?: number,
    groupIndex?: number,
    questionIndex?: number
) => {
    let sum = 0;
    for (let i = 0; i < (listIndex || 0); i++) {
        if (!bioReport?.listQuestion[i].isGroupStyle) {
            sum += bioReport?.listQuestion[i].questions.length || 0;
        }
    }
    return sum + (questionIndex || 0) + 1;
};

export const calculateReport = (bioReport: IBioReport) => {
    let finalTotalPoint = 0;
    let finalPoint = 0;
    let finalPercentage = 0;
    let totalViolated = 0;
    const groupViolated: IGroupViolated[] = [];
    const violatedQuestions: IBioReportQuestion[] = [];
    bioReport.listQuestion.forEach((list, listIndex) => {
        if (!list.isGroupStyle) {
            let totalPoint = 0;
            let point = 0;
            groupViolated.push({
                title: list.groupTitle || '',
                failQuestions: [],
                successQuestions: [],
            });
            list.questions.forEach((question, questionIndex) => {
                if (question.questionType === QuestionType.YES_NO) {
                    totalPoint += question.point;
                    if (question.answerBoolean) {
                        point += question.point;
                    }
                    if (question.isViolatedPoint) {
                        violatedQuestions.push(question);
                        if (!question.answerBoolean) {
                            totalViolated++;
                            groupViolated[groupViolated.length - 1].failQuestions.push({
                                ...question,
                                indexNumber: getQuestionNumber(
                                    bioReport,
                                    listIndex,
                                    undefined,
                                    questionIndex
                                ),
                            });
                        } else {
                            groupViolated[groupViolated.length - 1].successQuestions.push({
                                ...question,
                                indexNumber: getQuestionNumber(
                                    bioReport,
                                    listIndex,
                                    undefined,
                                    questionIndex
                                ),
                            });
                        }
                    }
                } else {
                    // Do nothing now
                }
            });
            list.totalPoint = totalPoint;
            list.point = point;
            list.percentage = (point * 100) / totalPoint || 0;

            finalTotalPoint += totalPoint;
            finalPoint += point;
        } else {
            let totalPoint = 0;
            let point = 0;
            list.groupQuestions.forEach((group, groupIndex) => {
                let subTotal = 0;
                let subPoint = 0;
                groupViolated.push({
                    title: group.groupTitle || '',
                    successQuestions: [],
                    failQuestions: [],
                });
                group.questions.forEach((question, questionIndex) => {
                    subTotal += question.point;
                    if (question.answerBoolean) {
                        subPoint += question.point;
                    }
                    if (question.isViolatedPoint) {
                        violatedQuestions.push(question);
                        if (!question.answerBoolean) {
                            totalViolated++;
                            groupViolated[groupViolated.length - 1].failQuestions.push({
                                ...question,
                                indexNumber: getQuestionNumber(
                                    bioReport,
                                    listIndex,
                                    groupIndex,
                                    questionIndex
                                ),
                            });
                        } else {
                            groupViolated[groupViolated.length - 1].successQuestions.push({
                                ...question,
                                indexNumber: getQuestionNumber(
                                    bioReport,
                                    listIndex,
                                    groupIndex,
                                    questionIndex
                                ),
                            });
                        }
                    }
                });
                totalPoint += subTotal;
                point += subPoint;
                group.point = subPoint;
                group.totalPoint = subTotal;
                group.percentage = (subPoint * 100) / subTotal || 0;
            });
            list.totalPoint = totalPoint;
            list.point = point;
            list.percentage = (point * 100) / totalPoint || 0;
            finalTotalPoint += totalPoint;
            finalPoint += point;
        }
    });
    finalPercentage = (finalPoint * 100) / finalTotalPoint || 0;

    return {
        finalResult: {
            finalPoint,
            finalTotalPoint,
            percentage: finalPercentage,
            totalViolated,
        },
        violatedQuestions: violatedQuestions || [],
        bioReport,
        groupViolated,
    };
};

export const compareViolatedQuestion = (reports: (IBioReport | undefined)[]) => {
    const ret: IBioReportViolatedListQuestion[] = [];
    if (reports[0]) {
        reports[0].listQuestion.forEach((list, listIndex) => {
            if (list.isGroupStyle) {
                const groupViolatedQuestions: {
                    isGroupStyle: boolean;
                    groupTitle: string;
                    yesAnswerTitle: string;
                    noAnswerTitle: string;
                    questions: IBioReportQuestion[];
                    point?: number;
                    totalPoint?: number;
                    percentage?: number;
                }[] = [];
                list.groupQuestions.forEach((groupQuestion, groupIndex) => {
                    const newGroupQuestion = {
                        ...groupQuestion,
                    };
                    const eachNewQuestionList: Omit<IBioReportQuestion, 'answerBoolean'>[] = [];
                    groupQuestion.questions.forEach((question, questionIndex) => {
                        if (
                            question.isViolatedPoint &&
                            question.questionType === QuestionType.YES_NO
                        ) {
                            const newQuestion = {
                                ...question,
                                ans0: question.answerBoolean,
                            };
                            for (let i = 1; i < reports.length; i++) {
                                const found = reports[i]?.listQuestion[listIndex].groupQuestions[
                                    groupIndex
                                ].questions.find((item) => item.dataCore === question.dataCore);
                                if (found) {
                                    newQuestion[`ans${i}`] = found.answerBoolean;
                                } else {
                                    newQuestion[`ans${i}`] = false;
                                }
                            }
                            eachNewQuestionList.push(newQuestion);
                        }
                    });
                    newGroupQuestion.questions = eachNewQuestionList;
                    groupViolatedQuestions.push(newGroupQuestion);
                });
                ret.push({
                    ...list,
                    groupViolatedQuestions,
                });
            } else {
                const violatedQuestions: Omit<IBioReportQuestion, 'answerBoolean'>[] = [];
                list.questions.forEach((question, questionIndex) => {
                    if (question.isViolatedPoint && question.questionType === QuestionType.YES_NO) {
                        const newQuestion = {
                            ...question,
                            ans0: question.answerBoolean,
                        };
                        for (let i = 1; i < reports.length; i++) {
                            const found = reports[i]?.listQuestion[listIndex].questions.find(
                                (item) => item.dataCore === question.dataCore
                            );
                            if (found) {
                                newQuestion[`ans${i}`] = found.answerBoolean;
                            } else {
                                newQuestion[`ans${i}`] = false;
                            }
                        }
                        violatedQuestions.push(newQuestion);
                    }
                });
                ret.push({
                    ...list,
                    violatedQuestions,
                });
            }
        });
    }
    return ret;
};

export const compareViolatedQuestionRetail = (reports: (IBioReportRetail | undefined)[]) => {
    const ret: IBioReportViolatedListQuestionRetail[] = [];
    if (reports[0]) {
        reports[0].listQuestion.forEach((list, listIndex) => {
            if (list.isGroupStyle) {
                const groupViolatedQuestions: {
                    isGroupStyle: boolean;
                    groupTitle: string;
                    yesAnswerTitle: string;
                    noAnswerTitle: string;
                    questions: IBioReportQuestionRetail[];
                    point?: number;
                    totalPoint?: number;
                    percentage?: number;
                }[] = [];
                list.groupQuestions.forEach((groupQuestion, groupIndex) => {
                    const newGroupQuestion = {
                        ...groupQuestion,
                    };
                    const eachNewQuestionList: Omit<IBioReportQuestionRetail, 'answerBoolean'>[] =
                        [];
                    groupQuestion.questions.forEach((question, questionIndex) => {
                        if (
                            question.isViolatedPoint &&
                            question.questionType === QuestionType.YES_NO
                        ) {
                            const newQuestion = {
                                ...question,
                                ans0: question.answer!.point > 0 ? true : false,
                            };
                            for (let i = 1; i < reports.length; i++) {
                                const found = reports[i]?.listQuestion[listIndex].groupQuestions[
                                    groupIndex
                                ].questions.find((item) => item.dataCore === question.dataCore);
                                if (found) {
                                    newQuestion[`ans${i}`] = found.answer!.point > 0 ? true : false;
                                } else {
                                    newQuestion[`ans${i}`] = false;
                                }
                            }
                            eachNewQuestionList.push(newQuestion);
                        }
                    });
                    newGroupQuestion.questions = eachNewQuestionList;
                    groupViolatedQuestions.push(newGroupQuestion);
                });
                ret.push({
                    ...list,
                    groupViolatedQuestions,
                });
            } else {
                const violatedQuestions: Omit<IBioReportQuestionRetail, 'answerBoolean'>[] = [];
                list.questions.forEach((question, questionIndex) => {
                    if (question.isViolatedPoint) {
                        const newQuestion = {
                            ...question,
                            ans0: question.answer!.point > 0 ? true : false,
                        };
                        for (let i = 1; i < reports.length; i++) {
                            const found = reports[i]?.listQuestion[listIndex].questions.find(
                                (item) => item.dataCore === question.dataCore
                            );
                            if (found) {
                                newQuestion[`ans${i}`] = found.answer!.point > 0 ? true : false;
                            } else {
                                newQuestion[`ans${i}`] = false;
                            }
                        }
                        violatedQuestions.push(newQuestion);
                    }
                });
                ret.push({
                    ...list,
                    violatedQuestions,
                });
            }
        });
    }
    return ret;
};

export const calculateReportRetail = (bioReport: IBioReportRetail) => {
    let finalTotalPoint = 0;
    let finalPoint = 0;
    let finalPercentage = 0;
    let totalViolated = 0;
    const groupViolated: IGroupViolatedRetail[] = [];
    const violatedQuestions: IBioReportQuestionRetail[] = [];
    bioReport.listQuestion.forEach((list, listIndex) => {
        let totalPoint = 0;
        let point = 0;
        groupViolated.push({
            title: list.groupTitle || '',
            failQuestions: [],
            successQuestions: [],
        });
        list.questions.forEach((question, questionIndex) => {
            totalPoint += Math.max(...question.points.map((o) => o.point));
            point += question.answer?.point ?? 0;
            if (question.isViolatedPoint) {
                violatedQuestions.push(question);
                if (point === 0) {
                    totalViolated++;
                    groupViolated[groupViolated.length - 1].failQuestions.push({
                        ...question,
                        indexNumber: getQuestionNumberRetail(
                            bioReport,
                            listIndex,
                            undefined,
                            questionIndex
                        ),
                    });
                } else {
                    groupViolated[groupViolated.length - 1].successQuestions.push({
                        ...question,
                        indexNumber: getQuestionNumberRetail(
                            bioReport,
                            listIndex,
                            undefined,
                            questionIndex
                        ),
                    });
                }
            }
        });
        list.totalPoint = totalPoint;
        list.point = point;
        list.percentage = (point * 100) / totalPoint || 0;

        finalTotalPoint += totalPoint;
        finalPoint += point;
    });
    finalPercentage = (finalPoint * 100) / finalTotalPoint || 0;

    return {
        finalResult: {
            finalPoint,
            finalTotalPoint,
            percentage: finalPercentage,
            totalViolated,
        },
        violatedQuestions: violatedQuestions || [],
        bioReport,
        groupViolated,
    };
};
