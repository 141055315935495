import { toast } from 'react-toastify';
import { CreateTopicDto, IPaginationTopic, ITopic } from 'shared/interface/topic.interface';
import api from '../../../../shared/service/api';

const url = '/topic';
export default class TopicService {
    static async find(page: number = 1, limit: number = 5): Promise<IPaginationTopic> {
        try {
            const res = await api.get(url, {
                params: { page, limit },
            });
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async getTopicDetail(id: string): Promise<ITopic> {
        try {
            const res = await api.get(`${url}/${id}`);
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async create(dto: CreateTopicDto, image?: File): Promise<ITopic> {
        try {
            const formData = new FormData();
            if (image) {
                formData.append('image', image as any);
            }
            formData.append('title', dto.title);
            formData.append('previewContent', dto.previewContent);
            formData.append('content', dto.content);
            if (dto.url) {
                formData.append('url', dto.url);
            }
            const res = await api.post(`${url}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async update(id: string, dto: CreateTopicDto, image?: File): Promise<ITopic> {
        try {
            const formData = new FormData();
            if (image) {
                formData.append('image', image as any);
            }
            formData.append('title', dto.title);
            formData.append('previewContent', dto.previewContent);
            formData.append('content', dto.content);
            if (dto.url) {
                formData.append('url', dto.url);
            }
            const res = await api.patch(`${url}/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async remove(id: string): Promise<ITopic> {
        try {
            const res = await api.delete(`${url}/${id}`);
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }
}
