import { IQuestionSetRetail } from 'shared/interface/question-set-retail.interface';
import api from './api';
import { toast } from 'react-toastify';
import { IPagination } from 'shared/interface/base.interface';

const url = '/question-set-retail';

export const QuestionSetRetailService = {
    getActive: async (): Promise<IQuestionSetRetail> => {
        try {
            const res = await api.get(`${url}/active`);
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    },

    findAll: async (
        page: number = 1,
        limit: number = 10
    ): Promise<IPagination<IQuestionSetRetail>> => {
        try {
            const res = await api.get(url, {
                params: {
                    page,
                    limit,
                },
            });
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    },

    create: async (dto: any): Promise<IQuestionSetRetail> => {
        try {
            const res = await api.post(url, dto);
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    },

    setActive: async (id: string): Promise<IQuestionSetRetail> => {
        try {
            const res = await api.patch(`${url}/${id}/active`);
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    },

    setInactive: async (id: string): Promise<IQuestionSetRetail> => {
        try {
            const res = await api.patch(`${url}/${id}/inactive`);
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    },

    update: async (id: string, dto: any): Promise<IQuestionSetRetail> => {
        try {
            const res = await api.patch(`${url}/${id}`, dto);
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    },

    remove: async (id: string): Promise<IQuestionSetRetail> => {
        try {
            const res = await api.delete(`${url}/${id}`);
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    },

    findOne: async (id: string): Promise<IQuestionSetRetail> => {
        try {
            const res = await api.get(`${url}/${id}`);
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    },
};
