import { toast } from 'react-toastify';
import { IQuestionSet, IQuestionSetPagination } from 'shared/interface/question-set.interface';
import api from 'shared/service/api';

const url = '/question-set';

export default class QuestionSetService {
    static async getActive(): Promise<IQuestionSet> {
        try {
            const res = await api.get(`${url}/active`);
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async findAll(page: number = 1, limit: number = 10): Promise<IQuestionSetPagination> {
        try {
            const res = await api.get(url, {
                params: {
                    page,
                    limit,
                },
            });
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async create(dto: any): Promise<IQuestionSet> {
        try {
            const res = await api.post(url, dto);
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async setActive(id: string): Promise<IQuestionSet> {
        try {
            const res = await api.patch(`${url}/${id}/active`);
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async setInactive(id: string): Promise<IQuestionSet> {
        try {
            const res = await api.patch(`${url}/${id}/inactive`);
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async update(id: string, dto: any): Promise<IQuestionSet> {
        try {
            const res = await api.patch(`${url}/${id}`, dto);
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async remove(id: string): Promise<IQuestionSet> {
        try {
            const res = await api.delete(`${url}/${id}`);
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }

    static async findOne(id: string): Promise<IQuestionSet> {
        try {
            const res = await api.get(`${url}/${id}`);
            return res.data;
        } catch (error: any) {
            //toast.error(`fail: ${error.message}`, { position: 'top-right' });
            throw error;
        }
    }
}
